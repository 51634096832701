import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
// import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
const Subheading = tw(SubheadingBase)`mb-4 text-sm font-medium`;
const Heading = tw(SectionHeading)`w-full`;
// const Description = tw(SectionDescription)`w-full text-center`;
const VerticalSpacer = tw.div`mt-4 w-full`;
const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;


const Column = styled.div`
  ${tw`md:w-1/3 lg:w-1/5 sm:w-1/4`}
`;

const Card = styled.div`
  ${tw`flex flex-col items-center sm:items-start text-center sm:text-left h-1 mx-4 px-2 py-6`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-normal font-bold text-lg leading-none`}
  }
  

  
`;



export default ({
  cards = null,
  heading = "What We Buy",
  subheading = "Our Business",
  // description = "Need money for emergencies? Unexpected expenses, medical bills, car repairs? Empty your jewelry box and I'll fill your wallet!",
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) title - the title of the card
   *  2) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    { title: 'Gold jewelry' },
    { title: 'Broken jewelry' },
    { title: 'Silver jewelry' },
    { title: 'Antique jewelry' },
    { title: 'Gold coins' },
    { title: 'Silver coins' },
    { title: 'Collectable coins' },
    { title: 'Silver flatware' },
    { title: 'Dental gold' },
    { title: 'Diamonds' },
    { title: 'Sterling silver' },
    { title: 'Chains' },
    { title: 'Charms' },
    { title: 'Class rings' },
    { title: 'Earrings' },
    { title: 'Estate jewelry' },
    { title: 'Gold grills' },
    { title: 'Gold watches' },
    { title: 'Ingots' },
    { title: 'Lockets' },
    { title: 'Money clips' },
    { title: 'Pendants' },
    { title: 'Pins and brooches' },
    { title: 'Platinum jewelry' },
    { title: 'Pocket watches' },
    { title: 'Precious metals' },
    { title: 'Rings' },
    { title: 'Scrap gold' },
    { title: 'Wedding bands' },
    { title: 'Wedding sets' },
    { title: 'Gift cards' },
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        {/* {description && <Description>{description}</Description>} */}
        <VerticalSpacer />
        {cards.map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      {/* <DecoratorBlob1 />
      <DecoratorBlob2 /> */}
    </Container>
  );
};
