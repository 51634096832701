import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import MainFeature from "components/features/ContactMap.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import { Container, ContentWithPaddingLg } from "components/misc/Layouts";
import Map from "images/map.jpg";
import styled from "styled-components";
import { Button } from "react-bootstrap";

// const Address = tw.span`leading-relaxed`;
// const AddressLine = tw.span`block`;
// const Email = tw.span`text-sm mt-6 block text-gray-500`;
// const Phone = tw.span`text-sm mt-0 block text-gray-500`;
const Subheading = tw.span`tracking-wider text-sm font-medium`;
const Description = tw.span`inline-block mt-8`;
const imageCss = tw`rounded-4xl`;
const Text = styled.div`
  ${tw`text-lg text-center text-gray-800`}
  p {
    ${tw`mt-2 text-center leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const handleClick = () => {
   
  window.open("http://maps.apple.com/?address=Bradenton+Cash+For+Gold&t=m");
};

export default () => {
  return (
    <AnimationRevealPage disabled>
      <Header />
      <MainFeature
        subheading={<Subheading>In Business Since 2010</Subheading>}
        heading={<>Bradenton Cash For Gold</>}
        description={
          <Description>
            <Button onClick={handleClick}>
              3515 14th Street West
              <br />
              Bradenton, FL 34205
              <br />
            </Button>
            <br />
            <br />
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="(941) 896-4805"
        imageSrc={Map}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      <Container>
        <ContentWithPaddingLg>
          <Text>
            <h1>Hours of Operation</h1>
            <p>
              <strong>
                Mon-Thurs: 9-7 <br />
                Fri-Sat: 10-5 <br />
                Sun by Appointment
              </strong>
            </p>
          </Text>
        </ContentWithPaddingLg>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
