import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithoutButton.js";
import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
// import { SectionHeading, Subheading as SubheadingBase } from "components/misc/Headings.js";

import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import Button from "react-bootstrap/Button";
import Filler from "components/features/ThreeColCenteredStatsPrimaryBackground";
import BBBRating from "components/testimonials/TwoColumnWithImageAndRating";
import WhatWeBuy from "components/cards/ThreeColContactDetails.js";
import ladyCash from "images/ladyCash2.jpg";
import rayPhoto from "images/ray.jpg";

export default () => {
  // const Heading = tw(
  //   SectionHeading
  // )`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  // const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  // const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;

  const handleClick = () => {
   
    window.open("http://maps.apple.com/?address=Bradenton+Cash+For+Gold&t=m");
};

  return (
    <AnimationRevealPage disabled>
      <Header />
      <MainFeature
        subheading={<Subheading>In Business Since 2010</Subheading>}
        heading={
        <>Bradenton Cash For Gold</>
      }
        description={
          <Description>
            <Button onClick={handleClick}>
              3515 14th Street West
              <br />
              Bradenton, FL 34205
              <br />
            </Button>
            <br />
            <br />
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="(941) 896-4805"
        imageSrc={ladyCash}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      <BBBRating />
      <WhatWeBuy />
      
      <MainFeature2
        subheading={<Subheading>About Us</Subheading>}
        heading={<>Estate and Gold Jewelry Specialist</>}
        description={
          <Description>
            Hi, I'm Ray, Bradenton and Sarasota's friendliest gold buyer! Since 2010, I've built my reputation on honesty and trust. I keep my overhead low which means more cash for your gold!
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        imageSrc={rayPhoto}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      <Filler />
      <Testimonial
        subheading=""
        heading={
          <>
            Customers Love Us.</>
        }
      />
      
        
      <Footer />
    </AnimationRevealPage>
  );
};
