import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
// import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Testify from "components/testimonials/TestimonialMain.js";
// import BBBRating from "components/testimonials/TwoColumnWithImageAndRating";
import Footer from "components/footers/MiniCenteredFooter.js";

// const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage disabled>
      <Header />
      {/* <BBBRating /> */}
      <Testify />
      <Footer />
    </AnimationRevealPage>
  );
};
