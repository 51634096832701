import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
//eslint-disable-next-line
import { css } from "styled-components/macro"; //eslint-disable-line
import {
  SectionHeading
} from "components/misc/Headings.js";
// import { SectionDescription } from "components/misc/Typography.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Container = tw.div`relative`;

const ThreeColumnContainer = styled.div`
  ${tw`flex flex-col items-center md:items-stretch md:flex-row flex-wrap md:justify-center max-w-screen-lg mx-auto py-20 md:py-24`}
`;
// const Subheading = tw(SubheadingBase)`mb-4 text-sm font-medium`;
const Heading = tw(SectionHeading)`w-full`;
// const Description = tw(SectionDescription)`w-full text-center`;

const VerticalSpacer = tw.div`mt-10 w-full`;

const Column = styled.div`
  ${tw`md:w-1/2 lg:w-1/3 max-w-sm`}
`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

const Card = styled.div`
  ${tw`flex flex-col sm:flex-row items-center sm:items-start text-center sm:text-left h-full mx-4 px-2 py-8`}
  .imageContainer {
    ${tw`border text-center rounded-full p-5 flex-shrink-0`}
    img {
      ${tw`w-6 h-6`}
    }
  }

  .textContainer {
    ${tw`sm:ml-4 mt-4 sm:mt-2`}
  }

  .title {
    ${tw`mt-4 tracking-wide font-bold text-xl leading-none`}
  }

  .description {
    ${tw`mt-1 sm:mt-4 font-medium text-secondary-100 leading-loose`}
  }
`;




export default ({
  cards = null,
  heading = "Areas We Serve",
}) => {
  /*
   * This componets has an array of object denoting the cards defined below. Each object in the cards array can have the key (Change it according to your need, you can also add more objects to have more cards in this feature component) or you can directly pass this using the cards prop:
   *  1) title - the title of the card
   *  2) description - the description of the card
   *  If a key for a particular card is not provided, a default value is used
   */

  const defaultCards = [
    {
      title: "Bradenton",
    },
    {
      title: "Oneco",
    },
    {
      title: "Ellenton",
    },
    {
      title: "Palmetto",
    },
    {
      title: "Parrish",
    },
    {
      title: "Rubonia",
    },
    {
      title: "Samoset",
    },
    {
      title: "Ruskin",
    },
    {
      title: "Myakka City",
    },
    {
      title: "Terra Ciea",
    },
    {
      title: "Anna Maria",
    },
    {
      title: "Bradenton Beach",
    },
    {
      title: "Holmes Beach",
    },
    {
      title: "Lakewood Ranch",
    },
    {
      title: "Cedar Hammock",
    },
    {
      title: "Palmasola",
    },
    {
      title: "Sun City",
    },
    {
      title: "Wimauma",
    },
    {
      title: "Riverview",
    },
    {
      title: "Brandon",
    },
    {
      title: "Ruskin",
    },
    {
      title: "Tampa",
    },
    {
      title: "Valrico",
    },
    {
      title: "Sarasota",
    },
    {
      title: "Longboat Key",
    },
    {
      title: "Siesta Key",
    },
    {
      title: "Tellevast",
    },
    {
      title: "University Park",
    },
    {
      title: "Lakewood Ranch",
    },
    {
      title: "Lido Key",
    },
    {
      title: "Bird key",
    },
    {
      title: "Englewood",
    },
    {
      title: "Osprey",
    },
    {
      title: "Laurel",
    },
    {
      title: "Nokomis",
    },
    {
      title: "Venice",
    },
    {
      title: "Fruitville",
    },
    {
      title: "Vamo",
    },
    {
      title: "Gulf Gate",
    },
    {
      title: "St. Petersburg",
    },
    {
      title: "Bay Pines",
    },
    {
      title: "Largo",
    },
    {
      title: "Clearwater",
    },
    {
      title: "Pinellas Park",
    },
    {
      title: "Palm Harbor",
    },

    
  ];

  if (!cards) cards = defaultCards;

  return (
    <Container>
      <ThreeColumnContainer>
        {/* {subheading && <Subheading>{subheading}</Subheading>} */}
        <Heading>{heading}</Heading>
        {/* {description && <Description>{description}</Description>} */}
        <VerticalSpacer />
        {cards.sort((a, b) => (a.title > b.title) ? 1 : -1).map((card, i) => (
          <Column key={i}>
            <Card>
              <span className="textContainer">
                <span className="title">{card.title || "Fully Secure"}</span>
                {/* <p className="description">
                  {card.description || "Lorem ipsum donor amet siti ceali ut enim ad minim veniam, quis nostrud."}
                </p> */}
              </span>
            </Card>
          </Column>
        ))}
      </ThreeColumnContainer>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
