import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { ContentWithPaddingXl, Container } from "components/misc/Layouts.js";
import {
  SectionHeading as Heading,
  Subheading as SubheadingBase,
} from "components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "images/svg-decorator-blob-7.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "images/svg-decorator-blob-8.svg";

const Subheading = tw(SubheadingBase)`text-center`;
const Testimonials = tw.div`flex flex-col lg:flex-row items-center lg:items-stretch`;
const TestimonialContainer = tw.div`mt-16 lg:w-1/3`;
const Testimonial = tw.div`px-4 text-center max-w-xs mx-auto flex flex-col items-center`;
const Quote = tw.blockquote`mt-5 text-gray-600 font-medium leading-loose`;
const CustomerName = tw.p`mt-5 text-gray-900 font-semibold uppercase text-sm tracking-wide`;

const DecoratorBlob1 = styled(SvgDecoratorBlob1)`
  ${tw`pointer-events-none -z-20 absolute left-0 top-0 h-56 w-56 opacity-15 transform -translate-x-2/3 -translate-y-12 text-teal-400`}
`;
const DecoratorBlob2 = styled(SvgDecoratorBlob2)`
  ${tw`pointer-events-none -z-20 absolute right-0 bottom-0 h-64 w-64 opacity-15 transform translate-x-2/3 text-yellow-500`}
`;

export default ({
  subheading = "Testimonials",
  heading = "What Our Customers Are Telling Us",
  testimonials = [
    {
      quote:
        "Ray was very nice and took care of me with a fair offer. This is the best place to sell gold I have found!",
      customerName: "Kelsi, Sarasota, FL",
    },
    {
      quote:
        "We spoke on the phone and when I brought my jewelry in he paid me almost $100 more than we discussed because he showed me that one piece I told him was 10k was actually 18k. Nice to be treated honestly.",
      customerName: "Monty, Palma Sola, FL",
    },
    {
      quote:
        "Great people and very knowledgeable! Highly recommend for antique jewelry.",
      customerName: "Heather, Bradenton, FL",
    },
  ],
  testimonialsTwo = [
    {
      quote: "I am 76 years old & have been taken advantage of before. Your ad said “senior owned & senior friendly”. You certainly are.",
      customerName: "Anne, Lakewood Ranch, FL",
    },
    {
      quote: "When I sold my late husband’s size 11 wedding band,  I only got $68.00 (from another buyer). A week later I needed to pick up my medication, so I sold you my matching size 7 band. You paid me almost twice what I got from one of your competitors for my husband’s much larger ring. I wish I had found you first.",
      customerName: "Milly, Oneco, FL",
    },
    {
      quote: "Thanks for taking the time to explain things to us. We were shocked when we got so much for a few pieces of broken gold & my wife’s odd earrings. We’ll give out your cards.",
      customerName: "Hank & Meg, Ruskin",
    },
  ],
  testimonialsThree = [
    {
        quote: "Got more than I expected. They didn’t turn off our electric and I had enough to fill my tank. Made my day.",
        customerName: "Jules, Bradenton, FL",
      },
      {
        quote: "Wow! Ray was professional, kind and knowledgeable. He did a GREAT job! You have jewelry to sell, this is the place for you. Sincerely, Julie, satisfied customer.",
        customerName: "Julie, Parrish, FL",
      },
      {
        quote: "I was offered $45 and Bradenton Cash for Gold, paid me $74 and they pay you in cash.",
        customerName: "Michelle, Sarasota, FL",
      },
  ],
  testimonialsFour = [
    {
        quote: "I waited while you bought a quantity of jewelry from a couple.  I’m homeless & only had a couple of pieces of silver, but you treated me with respect and paid me enough to eat dinner.",
        customerName: "Brian",
      },
      {
        quote: "Ray is great to deal with & didn’t guilt us, like others did, when we wanted to go elsewhere to compare prices. He had offered us $180, which was more than two others did, so we went back. We told him we needed money for a sonogram so he actually paid us $200.  We’ve made a good friend & have earned more money referring others.",
        customerName: "Joe, Bradenton, FL",
      },
      {
        quote: "My wife and I are both retired and needed to sell some jewelry but we had a bad experience once before, our neighbor told us to try Bradenton Cash for Gold. It was like night and day. They were friendly & very fair compaired to the first place we sold. We have referred our daughter Lorrie & she was happy too.",
        customerName: "Lewis, Oneco, FL",
      },
  ],
  testimonialsFive = [
    
      {
        quote: "When I tell my friends where to Sell Gold, I always say. 'Ray pays more.', 'Ray pays more'. When I took my mom to Ray (Bradenton Cash For Gold), she insisted I take her to a couple of other places. We ended up coming back to Ray’s because he offered more and paid her more.",
        customerName: "Samuel, Bradenton, FL",
      },
      {
        quote: "Don’t go anywhere else. Bradenton Cash for gold treated me very well.",
        customerName: "Drew, Bradenton, FL",
      },
      {
        quote: "I sold gold to 2 online gold buyers and didn’t feel I was getting paid enough. A friend referred me to Bradenton Cash For Gold and I was paid much more. When I told them they said, 'several other customers told them the same thing, that they got less selling on the internet, and they said 'we’re actually opening an online gold buying company for just that reason.'",
        customerName: "Marie, Lakewood Ranch, FL",
      },
  ]
}) => {
  return (
    <Container>
      <ContentWithPaddingXl>
        {subheading && <Subheading>{subheading}</Subheading>}
        <Heading>{heading}</Heading>
        <Testimonials>
          {testimonials.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                <Quote>"{testimonial.quote}"</Quote>
                <CustomerName>- {testimonial.customerName}</CustomerName>
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
        <Testimonials>
          {testimonialsTwo.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                <Quote>"{testimonial.quote}"</Quote>
                <CustomerName>- {testimonial.customerName}</CustomerName>
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
        <Testimonials>
          {testimonialsThree.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                <Quote>"{testimonial.quote}"</Quote>
                <CustomerName>- {testimonial.customerName}</CustomerName>
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
        <Testimonials>
          {testimonialsFour.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                <Quote>"{testimonial.quote}"</Quote>
                <CustomerName>- {testimonial.customerName}</CustomerName>
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
        <Testimonials>
          {testimonialsFive.map((testimonial, index) => (
            <TestimonialContainer key={index}>
              <Testimonial>
                <Quote>"{testimonial.quote}"</Quote>
                <CustomerName>- {testimonial.customerName}</CustomerName>
              </Testimonial>
            </TestimonialContainer>
          ))}
        </Testimonials>
      </ContentWithPaddingXl>

      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};
