import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import { ReactComponent as QuotesLeftIcon } from "../../images/quotes-l.svg";
import { ReactComponent as QuotesRightIcon } from "../../images/quotes-r.svg";
import { ReactComponent as ArrowLeftIcon } from "../../images/arrow-left-2-icon.svg";
import { ReactComponent as ArrowRightIcon } from "../../images/arrow-right-2-icon.svg";
import { ReactComponent as SvgDecoratorBlob1 } from "../../images/svg-decorator-blob-4.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../../images/svg-decorator-blob-5.svg";

import "slick-carousel/slick/slick.css";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;
const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-primary-500 text-center max-w-sm`;

const TestimonialSliderContainer = tw.div`mt-24`;
const TestimonialSlider = styled(Slider)``;
const Testimonial = tw.div`flex! flex-col items-center md:items-stretch md:flex-row md:justify-center outline-none`;
const ImageContainer = styled.div`
  ${tw`md:mx-3 lg:mx-6 w-2/3 md:w-4/12 rounded flex items-center max-w-xs md:max-w-none`}
  img {
    ${tw`rounded`}
  }
`;
const TextContainer = tw.div`md:mx-3 lg:mx-6 md:w-6/12 py-4 flex flex-col justify-between`;
const QuoteContainer = tw.div`relative p-6 md:p-8 lg:p-10 mt-4 md:mt-0`;
const Quote = tw.blockquote`text-center md:text-left font-medium text-xl lg:text-2xl xl:text-3xl`;
const CustomerInfo = tw.div`px-5 lg:px-10 text-center md:text-left mt-4 md:mt-0`;
const CustomerName = tw.h5`font-bold text-lg lg:text-xl xl:text-2xl text-primary-500`;
const CustomerTitle = tw.p`font-medium text-sm`;

const QuotesLeft = tw(QuotesLeftIcon)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute top-0 left-0`;
const QuotesRight = tw(QuotesRightIcon)`w-8 h-8 lg:w-10 lg:h-10 text-primary-500 absolute bottom-0 right-0`;

const SliderControlButtonContainer = styled.div`
  ${tw`absolute top-0 h-full flex items-end md:items-center z-20`}
  button {
    ${tw`text-secondary-500 hover:text-primary-500 focus:outline-none transition duration-300 transform hover:scale-125 transform -translate-y-2/3 md:translate-y-0`}
    svg {
      ${tw`w-8`}
    }
  }
`;

const NextArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="right-0">
    <button {...props}>
      <ArrowRightIcon />
    </button>
  </SliderControlButtonContainer>
);
const PreviousArrow = ({ currentSlide, slideCount, ...props }) => (
  <SliderControlButtonContainer tw="left-0">
    <button {...props}>
      <ArrowLeftIcon />
    </button>
  </SliderControlButtonContainer>
);

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`absolute w-32 top-0 left-0 -z-10 text-primary-500 opacity-25 transform -translate-x-full`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`absolute w-32 bottom-0 right-0 -z-10 text-pink-500 opacity-15 transform translate-x-2/3 translate-y-8`;

export default () => {
  /*
   * You can modify the testimonials shown by modifying the array below
   * You can add or remove objects from the array as you need.
   */
  const testimonials = [
    {
      quote:
        "Ray goes over and beyond for his customers. Best prices for items and has exceptional customer service. I highly recommend Bradenton Cash For Gold to anyone looking to sell gold or gift cards.",
      customerName: "Stina Thompson",
    },
    {
      quote:
        "We spoke on the phone and when I brought my jewelry in he paid me almost $100 more than we discussed because he showed me that one piece I told him was 10k was actually 18k. Nice to be treated honestly.",
      customerName: "Monty",
    },
    {
      quote:
        "My wife and I are both retired and needed to sell some jewelry but we had a bad experience once before, our neighbor told us to try Bradenton Cash for Gold. It was like night and day. They were friendly & very fair compaired to the first place we sold. We have referred our daughter Lorrie & she was happy too.",
      customerName: "Lewis",
    },
    {
      quote:
        "Got such an awesome deal! Never going to a pawn shop again!",
      customerName: "Nicole Pointer",
    },
    {
      quote:
        "This is the only place I come to when I need quick cash. Ray always gives u the most for your unwanted gold or silver. I'll never go any where else!!!",
      customerName: "Audrey Peters",
    },
    {
      quote:
        "I always appreciate going in cause Ray always takes care of your needs. And trys to help as much as possible I definitely recommend going to Bradenton Cash For Gold!",
      customerName: "Loree Milton",
    },
    {
      quote:
        "Great people & very knowledgeable! Highly recommend for antique jewelry.",
      customerName: "Heather Spitzer-Booth",
    },
    {
      quote:
        "Don't go anywhere else. Bradenton Cash for gold treated me very well.",
      customerName: "Drew",
    },
    {
      quote:
        "Ray was very nice and took care of me with a fair offer.  Would recommend",
      customerName: "Kelsi Emmott",
    },
    {
      quote:
        "Wonderful place, Ray is the best - thorough and involves one in the process",
      customerName: "Jan Brown",
    },
    {
      quote:
        "Had a family  member pass and was offered a fair price for their precious metals. Thank you very much! Would recommend to anyone looking for a fair compensation to your gold and silver!",
      customerName: "Joe Smith",
    },
    {
      quote:
        "Very professional and fair..friendly gentleman and will take his time explaining what he is doing..I highly recommend Bradenton Cash for Gold...wouldn't do business anywhere else..if your looking to get your money worth and not feel like you got ripped off I would definitely go there..never had a bad experience and have been here several times.",
      customerName: "Dee Chris",
    },
    {
      quote:
        "Very happy with my experience here!!!",
      customerName: "Nate F.",
    },
    {
      quote:
        "We got a fair price for the gold we wanted to exchange for cash.",
      customerName: "Victoria Frame",
    },
    
  ];
  return (
    <Container>
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>Customers Love Us.</HeadingTitle>
          <HeadingDescription>
            <a
            href="https://www.bbb.org/us/fl/bradenton/profile/gold-buyers/bradenton-cash-for-gold-0653-90117558/details"
            target="_blank"
            rel="noreferrer"
          >
            Our reputation preceeds us. But don't just take our word for it.
            Click here!
          </a>
          </HeadingDescription>
        </HeadingInfoContainer>
        <TestimonialSliderContainer>
          <TestimonialSlider nextArrow={<NextArrow />} prevArrow={<PreviousArrow />}>
            {testimonials.map((testimonial, index) => (
              <Testimonial key={index}>
                {/* <ImageContainer>
                  <img src={testimonial.imageSrc} alt={testimonial.customerName} />
                </ImageContainer> */}
                <TextContainer>
                  <QuoteContainer>
                    <QuotesLeft />
                    <Quote>{testimonial.quote}</Quote>
                    <QuotesRight />
                  </QuoteContainer>
                  <CustomerInfo>
                    <CustomerName>{testimonial.customerName}</CustomerName>
                    {/* <CustomerTitle>{testimonial.customerTitle}</CustomerTitle> */}
                  </CustomerInfo>
                </TextContainer>
              </Testimonial>
            ))}
          </TestimonialSlider>
        </TestimonialSliderContainer>
      </Content>
      {/* <DecoratorBlob1 />
      <DecoratorBlob2 /> */}
    </Container>
  );
};
