import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Header from "components/headers/light.js";
import WeBuy from "components/cards/ThreeColContactDetails.js";
import Footer from "components/footers/MiniCenteredFooter.js";


export default () => {
  return (
    <AnimationRevealPage disabled>
      <Header />
      <WeBuy />
      <Footer />
    </AnimationRevealPage>
  );
};
