import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import MainFeature from "components/features/TwoColWithButton.js";
import MainFeature2 from "components/features/TwoColWithoutButton.js";
import MainFeature3 from "components/features/MapBlurb.js";
// import Testimonial from "components/testimonials/ThreeColumnWithProfileImage.js";
import TestimonialCara from "components/testimonials/TwoColumnWithImage.js";
// import { SectionHeading, Subheading} from "components/misc/Headings.js";

import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter.js";
import Button from "react-bootstrap/Button";
// import Filler from "components/features/ThreeColCenteredStatsPrimaryBackground";
// import BBBRating from "components/testimonials/TwoColumnWithImageAndRating";
import WhatWeBuy from "components/cards/ThreeColContactDetails.js";
import ladyCash from "images/ladyCash2.jpg";
import rayPhoto from "images/ray.jpg";
import mapPhoto from "images/map2.jpg";

export default () => {
  // const Heading = tw(
  //   SectionHeading
  // )`mt-4 font-black text-left text-3xl sm:text-4xl lg:text-5xl text-center md:text-left leading-tight`;
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  // const HighlightedText = tw.span`bg-primary-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  // const HighlightedTextInverse = tw.span`bg-gray-100 text-primary-500 px-4 transform -skew-x-12 inline-block`;
  const Description = tw.span`inline-block mt-8`;
  const imageCss = tw`rounded-4xl`;

  const handleClick = () => {
    window.open("http://maps.apple.com/?address=Bradenton+Cash+For+Gold&t=m");
  };

  return (
    <AnimationRevealPage disabled>
      <Header />
      <MainFeature
        // subheading={<Subheading>In Business Since 2010</Subheading>}
        heading={<>Senior Friendly</>}
        description={
          <Description>
            <Button onClick={handleClick}>
              3515 14th Street West
              <br />
              Bradenton, FL 34205
            </Button>
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="(941) 896-4805"
        imageSrc={ladyCash}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      <MainFeature3
        // subheading={<Subheading>About Us</Subheading>}
        heading={<>Come In and See Us!</>}
        description={
          <Description>
            We're located at 3515 14th St W in Bradenton, just north of 36th Ave W. We'll be happy to answer any questions you have!
          </Description>
        }
        buttonRounded={false}
        textOnLeft={true}
        imageSrc={mapPhoto}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* <BBBRating /> */}
      <MainFeature2
        subheading={<Subheading>About Us</Subheading>}
        heading={<>Estate and Gold Jewelry Specialist</>}
        description={
          <Description>
            Hi, I'm Ray, Manatee and Sarasota's friendliest gold buyer! For
            years, I've built my reputation on honesty and trust. I keep my
            overhead low which means more cash for your gold! I'll always offer
            you a fair price, will never pressure you for a sale, and can answer
            your questions to make sure you're satisfied.
          </Description>
        } 
        buttonRounded={false}
        textOnLeft={false}
        imageSrc={rayPhoto}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      <WhatWeBuy />
      {/* <Filler /> */}
      <TestimonialCara />
      {/* <Testimonial
        heading={<>Customers Love Us.</>}
        subheading={
          <a
            href="https://www.bbb.org/us/fl/bradenton/profile/gold-buyers/bradenton-cash-for-gold-0653-90117558/details"
            target="_blank"
            rel="noreferrer"
          >
            Our reputation proceeds us. But don't just take our word for it.
            Click here!
          </a>
        }
      /> */}

      <Footer />
    </AnimationRevealPage>
  );
};