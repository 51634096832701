import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import {
  Container,
  ContentWithPaddingLg,
} from "components/misc/Layouts";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/light.js";
import Footer from "components/footers/MiniCenteredFooter";
import MainFeature1 from "components/features/ContactMap.js";
import MainFeature2 from "components/features/TwoColWithoutButton.js";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import Map from "images/map.jpg";
import rayPhoto from "images/ray.jpg";
// import { SectionHeading } from "components/misc/Headings";


const Subheading = tw.span`tracking-wider text-sm font-medium`;
const Description = tw.span`inline-block mt-8 `;
const imageCss = tw`rounded-4xl`;
// const HeadingRow = tw.div`flex`;
// const Heading = tw(SectionHeading)`text-gray-900 mb-10`;
const Text = styled.div`
  ${tw`text-lg  text-gray-800`}
  p {
    ${tw`mt-2 leading-loose`}
  }
  h1 {
    ${tw`text-3xl font-bold mt-10`}
  }
  h2 {
    ${tw`text-2xl font-bold mt-8`}
  }
  h3 {
    ${tw`text-xl font-bold mt-6`}
  }
  ul {
    ${tw`list-disc list-inside`}
    li {
      ${tw`ml-2 mb-3`}
      p {
        ${tw`mt-0 inline leading-normal`}
      }
    }
  }
`;

const handleClick = () => {
   
  window.open("http://maps.apple.com/?address=Bradenton+Cash+For+Gold&t=m");
};

export default () => {
  return (
    <AnimationRevealPage disabled>
      <Header />
      <MainFeature2
        subheading={<Subheading>About Us</Subheading>}
        heading={<>Estate and Gold Jewelry Specialist</>}
        description={
          <Description>
            Hi, I'm Ray, Bradenton and Sarasota's friendliest gold buyer! Since
            2010, I've built my reputation on honesty and trust. I keep my
            overhead low which means more cash for your gold!
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        imageSrc={rayPhoto}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      <MainFeature1 
        subheading={<Subheading>In Business Since 2010</Subheading>}
        heading={
        <>Bradenton Cash For Gold</>
      }
        description={
          <Description>
            <Button onClick={handleClick}>
              3515 14th Street West
              <br />
              Bradenton, FL 34205
              <br />
            </Button>
            <br />
            <br />
          </Description>
        }
        buttonRounded={false}
        textOnLeft={true}
        primaryButtonText="(941) 896-4805"
        imageSrc={Map}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      
      <Container>
        <ContentWithPaddingLg>
        <Text>
          <h1>Bradenton Cash For Gold</h1>
          <p>
            I have only one, convenient location, to keep our overhead low, so I
            can pay you more.
          </p>
          <p>
            <strong>
              I’ve been buying precious metals and jewelry, from people much
              like you, since opening in 2010.
            </strong>
          </p>
          <p>
            For many years I have also met with clients, in their homes or at
            their bank or mine, to purchase the valuables they wanted or needed
            to sell. Come in to my shop or call me for a private one-on-one
            appointment.
          </p>
          <p>
            <strong>
              My success has always been based on treating my clients with
              respect and always paying them the fair market price based on the
              highest, up to the minute, gold prices.
            </strong>
          </p>
          <p>
            Many gold buyers claim to be the best, the largest, or to pay the
            highest prices. You and I know most of those claims are just
            “advertising hype” or “lies,” made by the same buyers who will
            probably pay you just pennies on the dollar. I’LL WORK VERY HARD TO
            BEAT THEIR PRICE.
          </p>
          <p>
            <strong>
              I like to think I am the best, but I leave that to you (my
              customers) to decide. Come in, sell to me and see for yourself!
            </strong>
          </p>
          <p>Try us; you’ll like us!</p>
          <p><strong>Our success since opening in Bradenton, in 2010, proves we’re doing things right.</strong></p>
          <p>Our clients enjoy the way they’re treated at Bradenton Cash For Gold, so they feel comfortable referring their friends, relatives and co-workers to us! The amount of referrals they keep sending us tells me they like the experience of dealing with us. You will too!</p>
          <p><strong>I look forward to meeting you and working with you.</strong></p>      
          <h2>WE NOW BUY GIFT CARDS!</h2>
          <p>Get instant cash today.  We can beat their price.</p>
        </Text>
        </ContentWithPaddingLg>
      </Container>
      <Footer />
    </AnimationRevealPage>
  );
};
