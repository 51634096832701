import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Header from "components/headers/light.js";
import AreasServed from "components/cards/ThreeColAreasServed.js"
import Footer from "components/footers/MiniCenteredFooter.js";

export default () => {
  return (
    <AnimationRevealPage disabled>
      <Header />
      <AreasServed />
      <Footer />
    </AnimationRevealPage>
  );
};
