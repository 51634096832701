import React from "react";
// import ReactDOM from "react-dom";
import App from "./App";

import { hydrate, render } from "react-dom";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}


// import React from "react";
// import ReactDOM from "react-dom";
// import App from "./App";
// import Modal from "react-modal";

// Modal.setAppElement("#root");

// ReactDOM.render(
//   <App />,
//   document.getElementById("root")
// );
