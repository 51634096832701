import "tailwindcss/dist/base.css";
import "styles/globalStyles.css";
import React from "react";
import { css } from "styled-components/macro"; //eslint-disable-line
import ReactGA from "react-ga4";
import TagManager from 'react-gtm-module';
import MainPage from "pages/HomePage.js";
import AboutUsPage from "pages/AboutUs.js";
import ContactUsPage from "pages/ContactUs.js";
import AreasWeServePage from "pages/AreasWeServe.js";
import TestimonialsPage from "pages/Testimonials.js";
import WhatWeBuyPage from "pages/WhatWeBuy.js";

import ComponentRenderer from "ComponentRenderer.js";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "ScrollToTop";

ReactGA.initialize("G-P2EQXR7S89");

const tagManagerArgs = {
  gtmId: 'GTM-5QHJZJX'
}
TagManager.initialize(tagManagerArgs);

export default function App() {
  window.dataLayer.push({
    event: 'pageview'
  });
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <Route path="/components/:type/:subtype/:name">
          <ComponentRenderer />
        </Route>
        <Route path="/components/:type/:name">
          <ComponentRenderer />
        </Route>
        <Route path="/about-us" component={AboutUsPage}></Route>
        <Route path="/what-we-buy" component={WhatWeBuyPage}></Route>
        <Route path="/areas-we-serve" component={AreasWeServePage}></Route>
        <Route path="/testimonials" component={TestimonialsPage}></Route>
        <Route path="/contact" component={ContactUsPage}></Route>
        <Route path="/" component={MainPage}></Route>
      </Switch>
    </Router>
  );
}
